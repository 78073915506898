import colors from '../colors';
import * as about from './about';
import * as parties from './parties';
import albumList from './album';
import play from './play';
import learn from './learn';
import grow from './grow';
import cafe from './cafe';
import hairhub from './hairhub';
import playhouse from './playhouse';
import activities from './activities';

const email = 'info@myjamboreegh.com';
const website = 'www.myjamboreegh.com';

const {
  blue, purple, green, red, orange, amber, sunYellow, teal,
} = colors;

const navigationList = [
  { name: 'Home', to: '/', color: blue },
  { name: 'About us', to: '/about-us', color: purple },
  { name: 'Experience', to: '/experience', color: green },
  { name: 'Activities/Booking', to: 'https://myjamboreegh.com/booking', color: sunYellow },
  { name: 'PlayHouse', to: '/playhouse', color: teal },
  { name: 'Parties', to: '/parties', color: red },
  { name: 'Gallery', to: '/gallery', color: orange },
  { name: 'Contact us', to: '/contact-us', color: amber },
];

const slidingBanners = [
  {
    name: 'Playground',
    image: 'assets/images/banners/slide-4.jpg',
    url: null,
  },
  {
    name: 'Martial arts',
    image: 'assets/images/banners/slide-6.jpg',
    url: null,
  },
  {
    name: 'Ballet dancing',
    image: 'assets/images/banners/slide-5.jpg',
    url: null,
  },
  {
    name: 'Girl indoor',
    image: 'assets/images/banners/slide-2.jpg',
    url: null,
  },
  {
    name: 'Boy jumping in pool',
    image: 'assets/images/banners/slide-3.jpg',
    url: null,
  },
];

const experienceOptions = [
  {
    name: 'Play',
    link: 'play',
    color: blue,
    image: 'assets/images/play-thumb.jpg',
  },
  {
    name: 'Learn',
    link: 'learn',
    color: orange,
    image: 'assets/images/learn.jpg',
  },
  {
    name: 'Grow',
    link: 'grow',
    color: green,
    image: 'assets/images/grow-thumb.jpg',
  },
  {
    name: 'Jam Jam Cafe',
    link: 'cafe',
    color: red,
    image: 'assets/images/cafe-1.jpg',
  },
  {
    name: 'HairHub Kids',
    link: 'hairhub-kids',
    color: purple,
    image: 'assets/images/hairhub-1.jpg',
  },
];

const flyers = [
  // {
  //   name: 'Summer Camp',
  //   url: 'https://www.myjamboreegh.com/booking?package=summer-camp',
  //   color: blue,
  //   image: 'https://www.myjamboreegh.com/images/banners/ad_summer_camp.jpg',
  // },
  // {
  //   name: 'Half Term Break',
  //   url: 'https://www.myjamboreegh.com/booking?package=half-term-break',
  //   color: blue,
  //   image: 'https://www.myjamboreegh.com/images/banners/ad_half_term_break.jpg',
  // },
  {
    name: 'Good News Club',
    url: 'https://www.myjamboreegh.com/booking?package=activities',
    color: blue,
    image: 'https://www.myjamboreegh.com/images/banners/ad_good_news_club.jpg',
  },
  {
    name: 'Martial Arts',
    url: 'https://www.myjamboreegh.com/booking?package=activities',
    color: blue,
    image: 'https://www.myjamboreegh.com/images/banners/ad_martial-arts.jpg',
  },
  // {
  //   name: 'Christmas Camp',
  //   url: 'https://www.myjamboreegh.com/booking?package=christmas-camp',
  //   color: blue,
  //   image: 'https://www.myjamboreegh.com/images/banners/christmas-camp-2021.jpg',
  // },
  {
    name: 'Summer Camp',
    url: 'https://www.myjamboreegh.com/booking?package=holiday-camp',
    color: blue,
    image: 'https://www.myjamboreegh.com/images/banners/ad_summer-camp.jpg',
  },
  {
    name: 'Dance Class',
    url: 'https://www.myjamboreegh.com/booking?package=activities',
    color: blue,
    image: 'https://www.myjamboreegh.com/images/banners/ad_dance.jpg',
  },
  {
    name: 'Playhouse',
    url: 'https://www.myjamboreegh.com/booking?package=playgroup',
    color: blue,
    image: 'https://www.myjamboreegh.com/images/banners/ad_playhouse.jpg',
  },
  {
    name: 'Gymnastics',
    url: 'https://www.myjamboreegh.com/booking?package=activities',
    color: blue,
    image: 'https://www.myjamboreegh.com/images/banners/ad_gymnastics.jpg',
  },
  {
    name: 'Music',
    url: 'https://www.myjamboreegh.com/booking?package=activities',
    color: blue,
    image: 'https://www.myjamboreegh.com/images/banners/ad_music.jpg',
  },
  {
    name: 'Baby Swimming',
    url: 'https://www.myjamboreegh.com/booking?package=activities',
    color: blue,
    image: 'https://www.myjamboreegh.com/images/banners/ad_baby_swimming.jpg',
  },
  {
    name: 'Swimming',
    url: 'https://www.myjamboreegh.com/booking?package=activities',
    color: blue,
    image: 'https://www.myjamboreegh.com/images/banners/ad_swimming.jpg',
  },
  // {
  //   name: 'Storybox',
  //   url: 'https://www.myjamboreegh.com/booking?package=activities',
  //   color: blue,
  //   image: 'https://www.myjamboreegh.com/images/banners/ad_storybox.jpg',
  // },
  // {
  //   name: 'Destination Africa',
  //   url: 'https://www.myjamboreegh.com/booking?package=activities',
  //   color: blue,
  //   image: 'https://www.myjamboreegh.com/images/banners/destination_ad.jpg',
  // },
  {
    name: 'Toddler Playgroup',
    url: 'https://www.myjamboreegh.com/booking?package=playgroup',
    color: blue,
    image: 'https://www.myjamboreegh.com/images/banners/toddler_playgroup.jpg',
  },
  {
    name: 'After School Club',
    url: 'https://www.myjamboreegh.com/booking?package=activities',
    color: blue,
    image: 'https://www.myjamboreegh.com/images/banners/ad_after_school.jpg',
  },
];

const peeks = [
  {
    type: 'video',
    url: 'https://myjamboreegh.com/docs/video.mp4',
  },
  {
    image: 'assets/images/peek/peek-1.jpg',
  },
  {
    image: 'assets/images/peek/peek-2.jpg',
  },
  {
    image: 'assets/images/peek/peek-3.jpg',
  },
  {
    image: 'assets/images/peek/peek-4.jpg',
  },
  {
    image: 'assets/images/peek/peek-5.jpg',
  },
  {
    image: 'assets/images/peek/peek-6.jpg',
  },
  {
    image: 'assets/images/peek/peek-7.jpg',
  },
];

const slideOutOptions = [
  {
    id: 'booking',
    name: 'Booking',
    bgColor: red,
  },
  {
    id: 'timetable',
    name: 'Timetable',
    bgColor: purple,
  },
  {
    id: 'subscribe',
    name: 'Subscribe',
    bgColor: blue,
  },
  // {
  //   id: 'christmas-camp',
  //   name: 'Christmas Camp',
  //   bgColor: orange,
  // },
  {
    id: 'holiday-camp',
    name: 'Camp Schedule',
    bgColor: orange,
  },
];

export {
  navigationList,
  experienceOptions,
  flyers,
  peeks,
  about,
  parties,
  play,
  grow,
  slideOutOptions,
  learn,
  hairhub,
  cafe,
  playhouse,
  albumList,
  slidingBanners,
  email,
  website,
  activities,
};
