import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import RegistrationForm from '../components/RegistrationForm';
import { data } from '../utils';

const { playhouse: { programmes } } = data;

const PlayHouseRegistration = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  const { id } = useParams();
  const programme = programmes.find((prog) => prog.id === id);
  return (
    <>
      <div style={{
        background: 'url(https://myjamboreegh.com/images/banners/playgroup-bg.jpg)',
      }}
      >
        <div className="text-center max-w-5xl mx-auto md:pt-10 px-4 pb-20 bg-white">
          <div
            className="text-xl mb-4"
            style={{ color: programme?.bgColor }}
          >
            {`${programme?.name} Registration Form (${programme?.ages})`}
          </div>
          <RegistrationForm level={id} />
        </div>
      </div>
    </>
  );
};

export default PlayHouseRegistration;
