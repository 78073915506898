import { Button } from '@material-ui/core';
import React, { useState, useEffect, useReducer } from 'react';
import axios from 'axios';
import ConsentCard from '../components/ConsentCard';
import JTextField from '../components/JTextField';
// import { useParams } from 'react-router-dom';
import AlertModal from '../components/AlertModal';
import { colors, api } from '../utils';

const { blue } = colors;
const { playhouseRegistrationUrl } = api;
// const { playhouse: { programmes } } = data;
const consentSections = {
  'Urgent Medical Treatment': [
    {
      text: `I give consent for PlayHouse to give urgent basic 
      medical care, to my child if necessary.`,
    },
    {
      text: `In the event of an emergency, I give consent for PlayHouse 
      to seek medical assistance.`,
    },
    {
      text: `I agree to bear the full cost in case my child is sent to the hospital 
      due to an emergency.`,
    },
  ],
  'Administering Calpol': [
    {
      text: `I give consent for PlayHouse to administer Calpol to my child if they have
      a temperature of 37.5°C (99.5°F) or above, following the instructions and dosage relating
      to my child’s age on the Calpol packaging. I understand that I will be contacted before
      Calpol is given.`,
    },
  ],
  'Administering Antihistamine': [
    {
      text: `I give consent for PlayHouse to administer an antihistamine, if my child is
      showing signs of allergic reaction. I understand staff will follow the instructions and
      dosage relating to my child’s age on the medicine’s packaging. I understand I will be
      contacted before the antihistamine is given.`,
    },
  ],
  'Policies and Procedures': [
    {
      text: `I confirm that I have read and agree to PlayHouse Policies and terms and
      conditions including the policies online at www.myjamboreegh.com and policies posted
      at the facility.`,
    },
    {
      text: `I confirm that I am fully responsible for any tuition fees and/ or 
      costs relating to my child at  PlayHouse and/ or Jamboree Activity Centre and will bear same.`,
    },
  ],
  'Data Protection': [
    {
      text: `I hereby consent to PlayHouse storing and using parent/guardian, emergency
      contacts & the child/ren’s personal data for the purpose of running the day care.`,
    },
    {
      text: `I consent to our personal data being held electronically in the childcare management
      software which PlayHouse has subscribed to.`,
    },
  ],
  'Incident Report': [
    {
      text: `I understand that if PlayHouse suspects that a child in their care may
      have been abused or neglected, PlayHouse has a duty to report their
      concerns to the appropriate authorities.`,
    },
  ],
  'Video/Photography': [
    {
      text: `I give consent for staff of PlayHouse to photograph / video my child during
      any event e.g single and group observations, plays, days out etc. I understand that my
      child's image may appear in other children's photographs, on our website
      or social media pages.`,
    },
  ],
  'Sunscreen and insect repellent': [
    {
      text: `Where necessary, I will apply 8-hour sun cream protection and insect repellent to my
      child before dropping him or her off. I give permission for you to apply sun cream, or
      insect repellent which I have provided for my child as necessary during their day. I
      agree to provide this labeled with my child’s name.`,
    },
    {
      text: `In the case of my child becoming sun burnt, I give permission for you to provide any
      necessary first aid treatment.`,
    },
    {
      text: `I understand that I will need to provide suitable clothing for my child to wear at the
      facility.`,
    },
  ],
  'Parental Responsibility': [
    {
      text: `I agree for my child’s information to be discussed via 
      telephone, whatsapp and email.`,
    },
    {
      text: `I confirm that I have parental responsibility for the above child and will inform
      PlayHouse in writing, if another adult is collecting my child on my behalf.`,
    },
  ],
  'Notice Period': [
    {
      text: `I agree to give one month's written notice of withdrawal or pay a month's fees in lieu
      of notice.`,
    },
  ],
};

const initialState = {
  childName: '',
  parentName: '',
  errors: [],
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'reset':
      return initialState;
    case 'update':
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

const PlayHouseParentConsent = () => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [hasFormErrors, setHasFormErrors] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isSuccess, setIsSuccess] = useState();

  const validateForm = () => {
    setHasFormErrors(false);
    const updatedErrors = [];
    dispatch({ type: 'update', payload: { errors: [] } });
    const consentKeys = [];
    Object.keys(consentSections).forEach(((sec) => {
      consentSections[sec].forEach(({ text }) => consentKeys.push(text));
    }));
    [...consentKeys, 'childName', 'parentName'].forEach((field) => {
      if (!state[field] || state[field]?.trim() === '') {
        updatedErrors.push(field);
      }
    });
    dispatch({ type: 'update', payload: { errors: updatedErrors } });
    if (updatedErrors.length > 0) setHasFormErrors(true);
    return updatedErrors.length < 1;
  };

  const handleFormSubmission = () => {
    if (validateForm()) {
      const data = {
        ...state,
        parentConsentForm: true,
      };
      axios.post(playhouseRegistrationUrl, data)
        // axios.post('http://localhost:4000/controller/register.php', data)
        .then(() => {
          setIsSuccess(true);
          dispatch({ type: 'reset' });
        })
        .catch(() => {
          setIsSuccess(false);
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    }
    setIsSubmitting(false);
  };

  const handleChange = (key, val) => {
    const payload = {};
    payload[key] = val;
    dispatch({ type: 'update', payload });
    let updatedErrors = [];
    const { errors } = state;
    if (
      ['childName', 'parentName']
        .includes(key) && (!val || val.trim() === ''
      )
    ) {
      updatedErrors = errors.includes(key) ? errors : [...errors, key];
    } else {
      updatedErrors = errors.filter((err) => err !== key);
    }
    dispatch({
      type: 'update',
      payload: {
        errors: updatedErrors,
      },
    });
  };

  useEffect(() => {
    if ([false, true].includes(isSuccess)) {
      setShowModal(true);
    }
  }, [isSuccess]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <>
      {
        showModal
        && (
          <AlertModal
            success={isSuccess}
            title={isSuccess ? 'Application submitted!' : 'Failed to submit application'}
            message={isSuccess ? 'We\'ll get back to you shortly' : 'Please try again later.'}
            onClick={() => setShowModal(false)}
          />
        )
      }

      <div style={{
        background: 'url(https://myjamboreegh.com/images/banners/playgroup-bg.jpg)',
      }}
      >
        <div className="text-center max-w-5xl mx-auto md:pt-10 px-8 pb-20 bg-white">
          <div
            className="text-xl mb-4"
          >
            Parent Consent Form
          </div>
          <div className="md:flex text-left md:gap-x-2">
            <div className="w-full md:w-1/2">
              <JTextField
                label="Child's full name"
                value={state?.childName}
                onChange={
                  (e) => handleChange('childName', e.target.value)
                }
                hasError={state?.errors?.includes('childName')}
                errorMessage="Child's full name is required"
                required
              />
            </div>
          </div>
          <div className="mt-4 text-left">
            {
              Object.keys(consentSections).map((key) => (
                <div key={key} className="mb-8">
                  <p className="text-lg mb-2" style={{ color: blue }}>{key}</p>
                  {
                    consentSections[key].map(({ text }) => (
                      <ConsentCard
                        value={state[text]}
                        text={text}
                        hasError={state?.errors?.includes(text)}
                        onChange={(val) => handleChange(text, val)}
                      />
                    ))
                  }
                </div>
              ))
            }
          </div>
          <div className="md:flex text-left md:gap-x-2">
            <div className="w-full md:w-1/2">
              <JTextField
                label="Parent name"
                hasError={state?.errors?.includes('parentName')}
                onChange={(e) => handleChange('parentName', e.target.value)}
                value={state?.parentName}
              />
            </div>
          </div>

          <div className="text-left mt-4">
            <Button
              disabled={isSubmitting}
              variant="contained"
              color="primary"
              onClick={() => {
                if (!isSubmitting) handleFormSubmission();
              }}
            >
              {
                isSubmitting ? 'Submitting...' : 'Submit form'
              }
            </Button>
            {
              hasFormErrors
              && <p className="text-red-500 text-xs mt-4">Please make sure you have completed the form before submitting.</p>
            }
          </div>

        </div>
      </div>
    </>
  );
};

export default PlayHouseParentConsent;
