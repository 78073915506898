const defaultChildInfo = {
  firstName: '',
  middleName: '',
  surname: '',
  age: '',
  gender: '',
  homeAddress: '',
  dateOfBirth: '',
  nationality: '',
  // ethnicOrigin: '',
  religion: '',
  lunchIncluded: 'No',
  previousSchoolAttended: '',
  disabilities: '',
  activities: [],
};

const defaultGuardianInfo = {
  fullName: '',
  contactNumber: '',
  idCardType: '',
  idNumber: '',
  email: '',
  profession: '',
  residentialAddress: '',
  placeOfWork: '',
  workAddress: '',
};

const defaultAuthorizedPersonInfo = {
  fullName: '',
  contactNumber: '',
  idCardType: '',
  idNumber: '',
};

const defaultEmergencyContactInfo = {
  fullName: '',
  contactNumber: '',
};

const initialState = {
  lastChildId: 1,
  lastGuardianId: 1,
  lastAuthorizedPersonId: 1,
  lastEmergencyContactId: 1,
  children: [{ id: 1, ...defaultChildInfo }],
  guardians: [{ id: 1, ...defaultGuardianInfo }],
  authorizedPersons: [{ id: 1, ...defaultAuthorizedPersonInfo }],
  emergencyContacts: [{ id: 1, ...defaultEmergencyContactInfo }],
  otherInformation: '',
  preferredStartDate: '',
  acceptTerms: false,
  hearAbout: '',
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'updateChild':
      return {
        ...state,
        children: state.children.map(
          (obj) => (obj.id === action.payload.id ? { ...obj, ...action.payload } : obj),
        ),
      };
    case 'addChild':
      return {
        ...state,
        lastChildId: state.lastChildId + 1,
        children: [
          ...state.children,
          { id: state.lastChildId + 1, ...defaultChildInfo },
        ],
      };
    case 'removeChild':
      return {
        ...state,
        children: state.children.filter((child) => child.id !== action.id),
      };
    // GUARDIAN
    case 'updateGuardian':
      return {
        ...state,
        guardians: state.guardians.map(
          (obj) => (obj.id === action.payload.id ? { ...obj, ...action.payload } : obj),
        ),
      };
    case 'addGuardian':
      return {
        ...state,
        lastGuardianId: state.lastGuardianId + 1,
        guardians: [
          ...state.guardians,
          { id: state.lastGuardianId + 1, ...defaultGuardianInfo },
        ],
      };
    case 'removeGuardian':
      return {
        ...state,
        guardians: state.guardians.filter((guardian) => guardian.id !== action.id),
      };
    // AUTHORIZED PERSON
    case 'updateAuthorizedPerson':
      return {
        ...state,
        authorizedPersons: state.authorizedPersons.map(
          (obj) => (obj.id === action.payload.id ? { ...obj, ...action.payload } : obj),
        ),
      };
    case 'addAuthorizedPerson':
      return {
        ...state,
        lastAuthorizedPersonId: state.lastAuthorizedPersonId + 1,
        authorizedPersons: [
          ...state.authorizedPersons,
          { id: state.lastAuthorizedPersonId + 1, ...defaultAuthorizedPersonInfo },
        ],
      };
    case 'removeAuthorizedPerson':
      return {
        ...state,
        authorizedPersons: state.authorizedPersons.filter((person) => person.id !== action.id),
      };
    // EMERGENCY CONTACT
    case 'updateEmergencyContact':
      return {
        ...state,
        emergencyContacts: state.emergencyContacts.map(
          (obj) => (obj.id === action.payload.id ? { ...obj, ...action.payload } : obj),
        ),
      };
    case 'addEmergencyContact':
      return {
        ...state,
        lastEmergencyContactId: state.lastEmergencyContactId + 1,
        emergencyContacts: [
          ...state.emergencyContacts,
          { id: state.lastEmergencyContactId + 1, ...defaultEmergencyContactInfo },
        ],
      };
    case 'removeEmergencyContact':
      return {
        ...state,
        emergencyContacts: state.emergencyContacts
          .filter((emergencyContact) => emergencyContact.id !== action.id),
      };
    case 'update':
      return {
        ...state,
        ...action.payload,
      };
    case 'reset':
      return initialState;
    default:
      return state;
  }
};

export {
  initialState,
  reducer,
};
