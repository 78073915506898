import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import clsx from 'clsx';
import Home from './pages/Home';
import About from './pages/About';
import Experience from './pages/Experience';
import Parties from './pages/Parties';
import Gallery from './pages/Gallery';
import Contact from './pages/Contact';
import Slideout from './components/Slideout';
import NewsletterSubscription from './components/NewsletterSubscription';
import '@ptkdev/webcomponent-instagram-widget';
import Preloader from './components/Preloader';
import AlbumDetail from './pages/AlbumDetail';
import Playhouse from './pages/Playhouse';

function App() {
  const [showPreloader] = React.useState(false);
  const [showNewsletter, setShowNewsletter] = React.useState(true);
  return (
    <div>
      <Slideout setShowNewsletter={setShowNewsletter} />
      <Router>
        <Switch>
          <Route path="/" exact>
            <Home />
          </Route>
          <Route path="/about-us" exact>
            <About />
          </Route>
          <Route path="/experience">
            <Experience />
          </Route>
          <Route
            path="/booking"
            component={
              () => {
                window.location.href = 'https://myjamboreegh.com/booking';
                return null;
              }
            }
          />
          <Route path="/playhouse">
            <Playhouse />
          </Route>
          <Route path="/parties" exact>
            <Parties />
          </Route>
          <Route exact path="/gallery">
            <Gallery />
          </Route>
          <Route path="/gallery/:id">
            <AlbumDetail />
          </Route>
          <Route path="/contact-us" exact>
            <Contact />
          </Route>
        </Switch>
      </Router>
      {showPreloader && <Preloader />}
      <div className={clsx({ invisible: showPreloader })}>
        <NewsletterSubscription
          showNewsletter={showNewsletter}
          setShowNewsletter={setShowNewsletter}
        />
      </div>
    </div>
  );
}

export default App;
