/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function PopupDialogSlide({
  open, setOpen, children, hideBooking, url,
}) {
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogContent>
          {children}
        </DialogContent>
        <DialogActions>
          <div className="w-full flex space-x-4 justify-center mb-4">
            {
              !hideBooking
              && (
              <a
                className="jamb-button"
                target="_blank"
                rel="noreferrer"
                href={url}
              >
                Book now
              </a>
              )
            }

            <button
              type="button"
              className="jamb-button-outlined"
              onClick={handleClose}
              color="primary"
            >
              Close
            </button>

          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}

PopupDialogSlide.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  hideBooking: PropTypes.bool,
  children: PropTypes.element.isRequired,
  url: PropTypes.string,
};

PopupDialogSlide.defaultProps = {
  hideBooking: false,
  url: 'https://myjamboreegh.com/booking?package=activities',
};
