import colors from '../colors';

const {
  blue, purple, orange,
} = colors;

const grow = [
  {
    name: 'After School Homework Club',
    image: 'assets/images/experience/grow/afterschool.jpg',
    text: `
            <p class="mb-2">Homework helpers to the rescue! Join the After School Homework Club on weekdays from 1 – 5pm to get help with homework, assignments and projects in a safe, child friendly haven.</p>
        `,
    bgColor: blue,
    ageRange: 'ages 3 – 10',
    openingHours: 'Monday to Friday 1pm – 5pm (Terms and Conditions apply)',
  },
  {
    name: 'Baby Swimming',
    image: 'assets/images/experience/grow/baby-swimming.jpg',
    text: `
            <p class="mb-2">It’s excitement all around as we introduce the new Jamboree Baby 👶🏾Swimming Classes! 🏊🏼</p>

            <p class="mb-2">From 4 months - 2 years, this mummy & me 🤰🏾👶🏾 session is guaranteed to be an amazing bonding session for you and your little one while equipping your baby with this invaluable life skill. 🥳🥳</p>
            
            <p class="mb-2">Group sessions will run on Thursday and Saturday and are strictly on a pre registered basis. 
            ✨we have limited spaces available, so call Jamboree today to secure your spot🥳🥳.</p>
        `,
    bgColor: blue,
    ageRange: 'ages 4 months – 2 years',
    openingHours: 'Monday to Friday 1pm – 5pm (Terms and Conditions apply)',
  },
  {
    name: 'Dance',
    image: 'assets/images/experience/grow/dance.jpg',
    text: `
            <p class="mb-2">In collaboration with the renowned Vivies Dance Factory, we host the most amazing dance classes for children. Join us every Saturday as we move and groove with a range of dance styles including ballet a acro dance.
            </p>
        `,
    bgColor: purple,
    ageRange: 'ages 3 – 10',
    openingHours: 'Saturday 2pm – 5pm (Terms and Conditions apply)',
  },
  {
    name: 'Discovery Club',
    image: 'assets/images/experience/grow/discovery.jpg',
    text: `
            <p class="mb-2">Sign up for a class in adventure! With Discovery Club, nothing is off limits! Together with one of our instructors, your children will explore, create, challenge and discover! They will participate in fun, educational activities including science projects, gardening, cooking, design, crafts, music and much more! Join our Discovery Club to discover the Amazing You!</p>
        `,
    bgColor: orange,
    ageRange: 'ages 3 – 10',
    openingHours: 'Monthly (times to be communicated)',
  },
  {
    name: 'Free Play',
    image: 'assets/images/experience/grow/free-play.jpg',
    text: `
            <p class="mb-2">Play here… Play there... Play everywhere!</p>
            <p class="mb-2">Have lots of fun and explore in any of our three play areas! Check out our Indoor Play Town where kids can role play and be all they want to be! Try out lots of different activities from our JamJam supermarket, police station, café, clinic and more! The Outdoor Playground features many fun and safe play equipment including a trampoline, a jungle gym, swings and free play areas for hopscotch and football. Our Toddler Play Area features age appropriate toys and equipment to cater to the play needs of your little ones who are 3years and under.</p>
        `,
    bgColor: orange,
    ageRange: 'all ages',
    openingHours: 'Monday – Saturday 9am – 5pm (Terms and Conditions apply)',
  },
  {
    name: 'Good News Club',
    image: 'assets/images/experience/grow/good-news-club.jpg',
    text: `
            <p class="mb-2">Bible Reading, Stories & Songs, Art & Craft, Activities, Games and more.</p>
        `,
    bgColor: orange,
    ageRange: 'Free for all',
    openingHours: 'Saturday 10am – 11am',
  },
  {
    name: 'Gymnastics',
    image: 'assets/images/experience/grow/gymnastics.jpg',
    text: `
            <p class="mb-2">Tumble, twist and turn into confident pro gymnasts with our skilled gymnastics instructors at
            Jamboree!</p>
        `,
    bgColor: orange,
    ageRange: 'ages 3 – 10',
    openingHours: 'Friday 3pm – 5pm',
  },
  {
    name: 'Languages',
    image: 'assets/images/experience/grow/languages.jpg',
    text: `
            <p class="mb-2">Immerse yourself in a different language and culture through fun and interactive sessions which include songs, games, art, dance, play and more! “The limits of my language mean the limits of my world.” Ludwig Wittgenstein.</p>
        `,
    bgColor: orange,
    ageRange: 'ages 3 – 10',
    openingHours: 'Monday 3pm - 5pm',
  },
  {
    name: 'Martial Arts',
    image: 'assets/images/experience/grow/martial-arts.jpg',
    text: `
            <p class="mb-2">Join our Fantastic Martial Arts class every Wednesday from 3 - 5pm. Learn the essential skills and discipline to become a JamJam Ninja!</p>
        `,
    bgColor: orange,
    ageRange: 'ages 3 – 10',
    openingHours: 'Wednesday (3pm – 5pm)',
  },
  {
    name: 'Music',
    image: 'assets/images/experience/grow/music.jpg',
    text: `
            <p class="mb-2">Train their little fingers to master the piano, to strum the guitar and to create some amazing music! Jamboree is offering piano, guitar and drumming lessons to children who love music and are passionate about mastering the art form. Our skilled instructor will take your children through interactive one on one or small group sessions in a setting that allows your child to learn and develop at their own pace.</p>
        `,
    bgColor: orange,
    ageRange: 'ages 3 – 10',
    openingHours: 'Thursday (3pm – 5pm)',
  },
  {
    name: 'Playgroup',
    image: 'assets/images/grow-thumb.jpg',
    text: `
    <p class="mb-2">Jamboree PlayGroup is a fun and interactive play group session for children from 1 to 3 years old. Parents and guardians are welcome to join our toddlers every Wednesday from 10am – 12pm as they enjoy circle time, sing along, creative play, music and movement, sensory play and more.</p>
`,
    bgColor: orange,
    ageRange: 'ages 1 - 3',
    openingHours: 'Tuesday (3pm – 5pm)',
  },
  {
    name: 'Robotics & Coding',
    image: 'assets/images/experience/grow/robotics.jpg',
    text: `
            <p class="mb-2">Our Robotics and Coding class encourages the children to appreciate robots, technology, maths, mechanics and more, while developing their creativity and problem solving skills. Our children learn how to collaborate with others and practice team building skills to undertake exciting and innovative projects.</p>
        `,
    bgColor: orange,
    ageRange: 'ages 5 - 10',
    openingHours: 'Tuesday (3pm – 5pm)',
  },
  {
    name: 'Swimming',
    image: 'assets/images/experience/grow/swimming.jpg',
    text: `
            <p class="mb-2">We train swimmers at all levels to gain water confidence and learn important survival and safety
            techniques while encouraging a lifelong love of the water. Your children will not only have lots of
            fun swimming at Jamboree, they will progress at their own pace towards achieving the ultimate
            goal – swimming safely by themselves!</p>
        `,
    bgColor: orange,
    ageRange: 'ages 3 - 10',
    openingHours: 'Monday to Friday (2pm - 5pm) & Saturday (9am - 12pm)',
  },
];

export default grow;
